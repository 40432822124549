import Days from '../components/worked/Days.vue'
import Messages from '../components/messages/Messages.vue'
import Profile from '../components/Profile.vue'
import Settings from '../components/settings/Settings.vue'
import Controls from '../components/controls/Controls.vue'
import Coin from '../components/coin/Coin.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter);


export default new VueRouter({
    mode: 'history',
    base: '/',
    routes: [
        { name: 'worked', path: '/worked', component: Days, beforeEnter: authGuard },
        { name: 'messages', path: '/messages', component: Messages, beforeEnter: authGuard },
        { name: 'home', path: '/', component: Days, beforeEnter: authGuard },
        { name: 'profile', path: '/profile', component: Profile, beforeEnter: authGuard },
        { name: 'settings', path: '/settings', component: Settings, beforeEnter: authGuard },
        { name: 'controls', path: '/controls', component: Controls, beforeEnter: authGuard },
        { name: 'coin', path: '/coin', component: Coin, beforeEnter: authGuard },
    ]
})