import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        configuration: undefined,
    },
    actions: {
        loadConf({commit}) {
            return Vue.axios.get('/configuration/messages').then(response => {
                commit('setConfiguration', response.data);
            });
        },
        addOrigin({state, commit}, origin) {
            const configuration = state.configuration
            configuration.origins.push(origin)
            commit('setConfiguration', configuration)
        },
        save(_, configuration) {
            return Vue.axios.put('/configuration/messages', configuration)
        },
        removeOrigin({state, commit}, index) {
            const configuration = state.configuration
            configuration.origins.splice(index, 1);
            commit('setConfiguration', configuration)
        }
    },
    mutations: {
        setConfiguration(state, configuration) {
            state.configuration = configuration
        }
    }
}