import "moment/locale/fr";
import "vue-material-design-icons/styles.css";
import "./custom.scss";
import "./filters";
import "./utils/global";

import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import NProgress from "vue-nprogress";
import VerticalSeparator from "./components/layout/VerticalSeparator";
import Vue from "vue";
import VueMoment from "vue-moment";
import VueSidebarMenu from "vue-sidebar-menu";
import configureHttp from "./http";
import i18n from "./i18n";
// import VueSSE from 'vue-sse';
import moment from "moment";
import router from "./routes/router";
import store from "./stores/store";
import vSelect from "vue-select";

import { domain, clientId } from "../auth_config.json";
import { Auth0Plugin } from "./auth";

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});
const nprogress = new NProgress();
Vue.use(NProgress, {
  latencyThreshold: 50,
});
// Vue.use(VueSSE);
Vue.use(VueMoment, { moment });
Vue.use(VueSidebarMenu);
Vue.use(BootstrapVue);
Vue.component("v-select", vSelect);
Vue.component("v-s", VerticalSeparator);

Vue.config.productionTip = false;

configureHttp(
  () => {
    new Vue({
      render: (h) => h(App),
      router,
      store,
      i18n,
      nprogress,
    }).$mount("#app");
  },
  store,
  nprogress
);
