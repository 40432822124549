import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import { debounce } from "throttle-debounce";

export default (callback, store) => {
    const stopLoad = debounce(500, () => {
        store.commit('core/setLoading', false)
    })
    const instance = axios.create({
        timeout: 15000,
        headers: {
            'Content-Type': 'application/json'
        }
    })
    instance.interceptors.request.use(async config => {
        const token = (await Vue.prototype.$auth.getIdTokenClaims()).__raw;
        if (token) {
            config.headers = {
                Authorization: `Bearer ${token}`
            }
        }
        store.commit('core/setLoading', true)
        return config

    })
    instance.interceptors.response.use(response => {
        stopLoad()
        return response
    })
    Vue.use(
        VueAxios,
        instance
    );
    Vue.axios.defaults.baseURL = (process.env.VUE_APP_WORKED_API || "") + "/";
    console.log('Base api url', Vue.axios.defaults.baseURL)
    callback();
};


export const apiRoot = `${process.env.VUE_APP_WORKED_API}/api/v1/`
