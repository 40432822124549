<template>
  <div v-if="days">
    <b-list-group>
      <b-list-group-item
        v-if="skipStart"
        class="text-center"
      >
        <b-row>
          <b-col md="4">
            <b-btn
              variant="outline-primary"
              @click="showFromStart()"
            >
              {{ $t("Show days before") }}
              <arrow-collapse-up />
            </b-btn>
          </b-col>
        </b-row>
      </b-list-group-item>
      <template v-for="(day, x) in cdays">
        <b-list-group-item
          :key="`${day.timestamp}-startweek`"
          variant="primary"
          class="h4"
          v-if="x === 0 || (day.previousMonth && day.previousMonth !== day.month) || (day.previousMonth === 0 && day.month === 1)"
        >
          {{ day.dayFmt }}
          <span
            class="text-muted"
            :title="workedByMonth[day.dayFmt] * tjm + ' €'"
          >({{ workedByMonth[day.dayFmt] }})</span>
          <a @click="getCarInfo(day)">
            <i class="text-muted">
              <car title />
            </i>
          </a>
        </b-list-group-item>
        <b-list-group-item
          :variant="day.hasReminder ? 'warning' : 'success'"
          v-if="day.weekday === 0"
          :key="`${day.timestamp}-newweek`"
          class="h5"
        >
          Semaine {{ day.week }}
          <b-btn
            @click="checkReminder(day)"
            variant="info"
            v-if="day.hasReminder"
          >
            <eye-check />
          </b-btn>
        </b-list-group-item>
        <b-list-group-item :key="`${day.timestamp}-day`">
          <b-btn
            size="sm"
            variant="outline-primary"
            @click="setEditDay(day)"
          >
            <pencil />
          </b-btn>
          <span
            v-if="day.worked"
            class="text-success h3 status"
          >✔</span>
          <span
            v-else
            class="text-danger h3 status"
          >✗</span>
          {{ day.hrFormat | cap }}
          <span :class="{ 'text-info': day.duration !== 8 }">
            <span v-if="day.worked">({{ day.duration }}h)</span>
            <small class="note">
              <i>{{ day.activity }}</i>
            </small>
          </span>
        </b-list-group-item>
      </template>
    </b-list-group>
    <b-modal id="car-info">
      <table class="text-center">
        <tr
          v-for="day in carInfo"
          :key="day.dayFmt"
        >
          <td>{{ (day.day * 1000) | date("DD/MM/YYYY") }}</td>
          <td>Leroy Merlin</td>
          <td>Avenue de l'Avenir 59650 Villeneuve-d'Ascq</td>
          <td></td>
          <td>25</td>
        </tr>
      </table>
    </b-modal>
    <b-modal
      v-if="editDay"
      id="edit-day"
      @ok="onEdit"
    >
      <p class="h3">{{ editDay.hrFormat | cap }}</p>
      <b-form-group>
        <b-form-checkbox v-model="editDay.worked">Jour travaillé ?</b-form-checkbox>
      </b-form-group>
      <b-form-group>
        <b-form-input
          type="number"
          step="1"
          min="0"
          max="8"
          v-model.number="editDay.duration"
          placeholder="Durée"
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <b-form-input
          v-model="editDay.activity"
          placeholder="note"
        ></b-form-input>
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
  import { mapState } from "vuex";
  import Car from "vue-material-design-icons/Car";
  import Pencil from "vue-material-design-icons/Pencil";
  import EyeCheck from "vue-material-design-icons/EyeCheck";
  import ArrowCollapseUp from "vue-material-design-icons/ArrowCollapseUp";
  import RouteContext from "../../mixins/routeContext";

  export default {
    mixins: [RouteContext],
    created () {
      this.refresh();
    },
    components: { Car, Pencil, ArrowCollapseUp, EyeCheck },
    data () {
      return {
        skipStart: true,
        editDay: undefined,
      };
    },
    computed: {
      ...mapState("worked", [
        "days",
        "counts",
        "dols",
        "tjm",
        "today",
        "carInfo",
        "reminders",
      ]),
      workedByMonth () {
        const wbm = {};
        for (let day of this.cdays) {
          if (!wbm[day.dayFmt]) {
            wbm[day.dayFmt] = 0;
          }
          if (day.worked) {
            wbm[day.dayFmt]++;
          }
        }
        return wbm;
      },
      routeInfo () {
        return {
          title: this.$t("worked"),
        };
      },
      firstDay () {
        if (this.days && this.days.length) {
          const fday = this.days[0];
          const dayFormat = this.$moment(fday.day * 1000).format(
            "YYYY-MM"
          );
          return {
            id: dayFormat,
            dcount: this.counts[dayFormat],
            dcountTjm: this.counts[dayFormat] * this.tjm,
          };
        }
        return {};
      },
      lastMonth () {
        return this.$moment().subtract(1, "month");
      },
      cdays () {
        const cdays = [];
        let previous;
        for (let day of this.days) {
          const date = this.$moment(day.day * 1000);
          if (this.skipStart && date < this.lastMonth) {
            continue;
          }
          const dayFmt = date.format("YYYY-MM");
          const count = this.counts[dayFmt] || 0;
          const month = date.month();
          const weekday = date.weekday();
          const week = date.week();
          const timestamp = date.format("X");
          const hrFormat = date.format("dddd DD MMMM");
          const previousMonth =
            previous && this.$moment(previous.day * 1000).month();
          const reminder = this.reminders[
            `${date.format("YYYY")}-${week}`
          ];
          cdays.push({
            id: day.id,
            dayFmt,
            timestamp,
            hasReminder: reminder && !reminder.done,
            reminder,
            count,
            title: (count * this.tjm).toFixed(2),
            month,
            previousMonth,
            weekday,
            week,
            hrFormat,
            worked: day.worked,
            duration: day.duration,
            activity: day.activity,
          });
          previous = day;
        }
        return cdays;
      },
    },
    methods: {
      refresh () {
        this.$store.dispatch("worked/loadDays").then(() => {
          window.scrollTo(0, document.body.scrollHeight);
        });
        this.$store.dispatch("worked/loadReminders");
      },
      checkReminder (day) {
        this.$store
          .dispatch("worked/updateReminder", { reminderId: day.reminder.id, done: true })
          .then(this.refresh);
      },
      getCount (day) {
        return this.counts[this.$moment(day).format("YYYY-MM")];
      },
      showFromStart () {
        this.skipStart = false;
      },
      getCarInfo (day) {
        const date = this.$moment(day);
        const start = date.format("X");
        const stop = date.add(1, "months").format("X");
        this.$store
          .dispatch("worked/loadCarInfo", { start, stop })
          .then(() => {
            this.$bvModal.show("car-info");
          });
      },
      setEditDay (day) {
        this.editDay = {
          id: day.id,
          activity: day.activity,
          duration: day.duration,
          worked: day.worked,
        };
        setTimeout(() => {
          this.$bvModal.show("edit-day");
        });
      },
      onEdit () {
        this.$store.dispatch("worked/putDay", this.editDay).then(() => {
          this.$bvToast.toast(this.$t("Saved"), {
            title: "Update OK",
            variant: "success",
          });
        });
      },
    },
  };
</script>
<style scoped>
i.text-muted {
  margin-left: 10px;
}
span.status {
  position: relative;
  top: 6px;
  left: 6px;
  margin-right: 6px;
}
.note {
  margin-left: 10px;
}
</style>