<template>
  <div>
    <b-row>
      <br />
      <b-col
        md="4"
        offset-md="4"
        sm="12"
        offset-sm="0"
      >
        <b-table-simple
          hover
          small
          caption-top
          responsive
          class="text-center card"
        >

          <caption class="text-center">Valeurs cryptos</caption>
          <b-thead head-variant="dark">
            <b-tr>
              <b-th>type</b-th>
              <b-th>value</b-th>
              <b-th>my dols</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr class="align-middle">
              <b-td>Dogecoin</b-td>
              <b-td>{{crypto.DOGE.value}} €</b-td>
              <b-td>{{crypto.DOGE.my}}</b-td>
            </b-tr>
            <b-tr class="align-middle">
              <b-td>Bitcoin</b-td>
              <b-td>{{crypto.BTC.value}} €</b-td>
              <b-td>{{crypto.BTC.my}}</b-td>
            </b-tr>
            <b-tr class="align-middle">
              <b-td>Ethereum</b-td>
              <b-td>{{crypto.ETH.value}} €</b-td>
              <b-td>{{crypto.ETH.my}}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import RouteContext from "../../mixins/routeContext";
  import axios from "axios"

  export default {
    mixins: [RouteContext],
    components: {},
    data () {
      return {
        crypto: {
          "DOGE": {
            date: undefined,
            value: undefined,
          },
          "BTC": {
            date: undefined,
            value: undefined,
          },
          "ETH": {
            date: undefined,
            value: undefined,
          }
        }
      }
    },
    computed: {
      routeInfo () {
        return {
          title: this.$t("coin")
        };
      },
    },
    created () {
      axios.get('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,dogecoin,ethereum&vs_currencies=eur').then(response => {
        this.crypto["DOGE"].value = response.data.dogecoin.eur
        this.crypto["DOGE"].my = (response.data.dogecoin.eur * 0.07242128).toFixed(2)
        this.crypto["BTC"].value = response.data.bitcoin.eur
        this.crypto["BTC"].my = (response.data.bitcoin.eur * 0.02819656).toFixed(2)
        this.crypto["ETH"].value = response.data.ethereum.eur
        this.crypto["ETH"].my = (response.data.ethereum.eur * 0.07242128).toFixed(2)
      })
    }
  }
</script>
<style scoped>
.table-responsive {
  border: 1px solid #ccc;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
</style>