import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        days: undefined,
        reminders: {},
        counts: undefined,
        dols: undefined,
        tjm: undefined,
        today: undefined,
        carInfo: undefined
    },
    actions: {
        loadDays({commit}) {
            return Vue.axios.get(`/days`).then(response => {
                commit('setDays', response.data.days)
                commit('setCounts', response.data.counts)
                commit('setDols', response.data.dols)
                commit('setTjm', response.data.tjm)
                commit('setToday', response.data.today)
            })
        },
        loadReminders({ commit }) {
            return Vue.axios.get(`/reminders`).then(response => {
                const reminders = {}
                for (let reminder of (response.data.reminders || [])) {
                    reminders[`${reminder.year}-${reminder.week}`] = reminder
                }
                commit('setReminders', reminders)
            })
        },
        updateReminder(_, {reminderId, done}) {
            return Vue.axios.put(`/reminders/${reminderId}`, {done}).then(response => {
                console.log(response.data)
            })
        },
        loadCarInfo({commit}, {start, stop}) {
            return Vue.axios.get(`/car/${start}/${stop}`).then(response => {
                commit('setCarInfo', response.data)
            })
        },
        putDay({dispatch}, day) {
            return Vue.axios.put(`/days/${day.id}`, day).then(() => {
                dispatch('loadDays')
            })
        },
        putReminder({ dispatch }, {id, days}) {
            return Vue.axios.put(`/reminders/${id}`, {days}).then(() => {
                dispatch('loadDays')
            })
        }
    },
    mutations: {
        setDays(state, days) {
            state.days = days
        },
        setReminders(state, reminders) {
            state.reminders = reminders
        },
        setCounts(state, counts) {
            state.counts = counts
        },
        setDols(state, dols) {
            state.dols = dols
        },
        setTjm(state, tjm) {
            state.tjm = tjm
        },
        setToday(state, today) {
            state.today = today
        },
        setCarInfo(state, carInfo) {
            state.carInfo = carInfo
        }
    },
    getters: {}
}