import Vue from 'vue'
export default {
    namespaced: true,
    state: {
        plop: 1
    },
    actions: {
        async testAuthenticatedRoute() {
            return Vue.axios.get('/api/external');
        }
    },
    mutations: {},
    getters: {}
}