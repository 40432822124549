<template>
  <div v-if="!$auth.loading">
    <b-btn
      v-if="!$auth.isAuthenticated"
      @click="login"
    >{{$t('Log in')}}</b-btn>
    <b-dropdown
      v-if="$auth.isAuthenticated"
      id="dropdown-1"
      :text="$auth.user.nickname"
      class="m-md-2"
      right
    >
      <b-dropdown-item @click="go('profile')">{{$t('Profile')}}</b-dropdown-item>
      <b-dropdown-item @click="logout">{{$t('Log out')}}</b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
  import RouteContext from "../mixins/routeContext";

  export default {
    mixins: [RouteContext],
    methods: {
      // Log the user in
      login () {
        this.$auth.loginWithRedirect();
      },
      // Log the user out
      logout () {
        this.$auth.logout({
          returnTo: window.location.origin
        });
      },
      go (name) {
        this.$router.push({ name });
      }
    }
  };
</script>