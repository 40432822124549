<template>
    <div v-if="!$auth.loading">
        <div>
            <img :src="$auth.user.picture" />
            <h2>{{ $auth.user.name }}</h2>
            <p>{{ $auth.user.email }}</p>
        </div>

        <div>
            <pre>{{ JSON.stringify($auth.user, null, 2) }}</pre>
        </div>
        <b-btn @click="authProfile">Get auth profile</b-btn>
    </div>
</template>
<script>
import RouteContext from "../mixins/routeContext";

export default {
    mixins: [RouteContext],
    computed: {
        routeInfo() {
            return {
                title: this.$t("Profile")
            };
        }
    },
    methods: {
        authProfile() {
            this.$store
                .dispatch("auth/testAuthenticatedRoute")
                .then(response => {
                   this.$bvToast.toast(
                        response.data.msg,
                        {
                            title: 'Authenticated connection to API OK',
                            variant: 'success'
                        }
                    );
                }, error => {
                     this.$bvToast.toast(
                        error.message,
                        {
                            title: 'Error on auth route test',
                            variant: 'danger'
                        }
                    );
                });
        }
    }
};
</script>