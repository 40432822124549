import Vue from 'vue'
import Vuex from 'vuex'
import core from './core'
import settings from './settings'
import auth from './auth'
import layout from './layout'
import worked from './worked'
import messages from './messages'

Vue.use(Vuex)


export default new Vuex.Store({
    modules: {
        core,
        auth,
        settings,
        layout,
        worked,
        messages
    }
})