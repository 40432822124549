<template>
  <div>
    <b-row>
      <b-col
        md="4"
        offset-md="4"
        sm="12"
        offset-sm="0"
      >
        <b-table-simple
          hover
          small
          caption-top
          responsive
          class="text-center"
        >

          <caption class="text-center">Wake on lan</caption>
          <b-thead head-variant="dark">
            <b-tr>
              <b-th>machine</b-th>
              <b-th>actions</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td class="align-middle">Sphinx</b-td>
              <b-td>
                <b-button
                  class="action-button"
                  @click="wol('sphinx')"
                >
                  <power-plug-icon />
                </b-button>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td class="align-middle">Scorpion</b-td>
              <b-td>
                <b-button
                  class="action-button"
                  @click="wol('scorpion')"
                >
                  <power-plug-icon />
                </b-button>

              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import RouteContext from "../../mixins/routeContext";
  import PowerPlugIcon from "vue-material-design-icons/PowerPlugOutline";
  import axios from "axios"

  export default {
    mixins: [RouteContext],
    components: { PowerPlugIcon },
    computed: {
      routeInfo () {
        return {
          title: this.$t("controls")
        };
      },
    },
    methods: {
      wol (device) {
        axios.create({
          baseURL: 'https://controls.utopland.net',
          timeout: 1000,
          headers: { 'api-key': process.env.VUE_APP_CONTROLS_API_TOKEN }
        }).post(`/wol/${device}`).then(() => {
          this.$bvToast.toast(
            "Wol packed send",
            {
              title: 'Ok !',
              variant: 'success'
            }
          );
        });
      }
    }
  };
</script>
<style scoped>
.table-responsive {
  border: 1px solid #ccc;
}
</style>