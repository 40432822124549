<template>
  <sidebar-menu
    :menu="menu"
    @toggle-collapse="onToggleCollapse"
    width="180px"
    class="menu"
    :collapsed="collapsed"
  >
    <a
      class="logo"
      slot="header"
      href="/"
    >
      <span class="logo-wrapper">
        <img
          src="../assets/logo.svg"
          alt="Utopland admin"
        />
      </span>
    </a>
    <span slot="toggle-icon">
      <chevron-right v-if="collapsed" />
      <chevron-left v-else />
    </span>
  </sidebar-menu>
</template>

<script>
  import Vue from "vue";
  import { SidebarMenu } from "vue-sidebar-menu";
  import ChevronLeft from "vue-material-design-icons/ChevronLeft";
  import ChevronRight from "vue-material-design-icons/ChevronRight";
  import Graph from "vue-material-design-icons/Graph";
  import Cog from "vue-material-design-icons/Cog";
  import CoinIcon from "vue-material-design-icons/Bitcoin";
  import TimelineClock from "vue-material-design-icons/TimelineClock";
  import MessageIcon from "vue-material-design-icons/Message";
  import ControllerIcon from "vue-material-design-icons/ControllerClassic";
  Vue.component("graph", Graph);
  Vue.component("settings", Cog);
  Vue.component("timelineclock", TimelineClock);
  Vue.component("message-icon", MessageIcon);
  Vue.component("control-icon", ControllerIcon);
  Vue.component("coin-icon", CoinIcon);
  export default {
    components: {
      ChevronLeft,
      ChevronRight,
      SidebarMenu
    },
    methods: {
      onToggleCollapse (folded) {
        this.collapsed = folded;
        localStorage.setItem("menuCollapsed", folded ? "true" : "false");
        this.$emit("onMenuCollapse", folded);
      }
    },
    data () {
      return {
        collapsed: localStorage.getItem("menuCollapsed") === "true"
      };
    },
    computed: {
      menu () {
        return [
          {
            href: "/worked",
            alias: ["/worked*"],
            title: this.$t("worked").capitalize(),
            icon: {
              element: "timelineclock",
              class: "menu-icon"
            }
          },
          {
            href: "/messages",
            alias: ["/messages*"],
            title: this.$t("messages").capitalize(),
            icon: {
              element: "message-icon",
              class: "menu-icon"
            }
          },
          {
            href: "/controls",
            alias: ["/controls*"],
            title: this.$t("controls").capitalize(),
            icon: {
              element: "control-icon",
              class: "menu-icon"
            }
          },
          {
            href: "/coin",
            alias: ["/coin*"],
            title: this.$t("coin").capitalize(),
            icon: {
              element: "coin-icon",
              class: "menu-icon"
            }
          }
        ];
      }
    }
  };
</script>

<style lang="scss" scoped>
@import "../styles/variable";

.logo {
  height: 60px;
  overflow: hidden;
  span {
    display: block;
    height: 52px;
    overflow: hidden;
    border-bottom: 2px solid $secondary;
    padding: 10px;
    img {
      height: 100%;
    }
  }
}

/deep/ .vsm--item {
  transition: opacity 0.2s;
}

/deep/ .menu-icon {
  font-size: 1.5em;
  background-color: $dark !important;
  padding-bottom: 15px;
  svg {
    top: 3px;
    left: 3px;
  }
}

.menu,
/deep/ .vsm--toggle-btn {
  background-color: $dark;
}
.logo-wrapper {
  padding: 3px !important;
}
</style>