<template>
  <div>
    <h3>
      <message-icon /> Message configurator
    </h3>
    <hr />
    <b-row v-if="configuration">
      <b-col
        md="4"
        v-for="(origin, i) in configuration.origins"
        :key="origin.Source"
      >
        <b-card :header="`source ${origin.source}`">
          <b-card-text>
            <b-list-group>
              <b-list-group-item
                v-for="(target, j) in origin.targets"
                :key="i+j"
              >
                <b-form inline>
                  <b-form-input
                    type="email"
                    v-model="target.email"
                  />
                  <b-button
                    variant="warning"
                    class="text-white"
                    @click="removeTarget(target, origin.targets)"
                  >X</b-button>
                </b-form>
              </b-list-group-item>
            </b-list-group>
            <hr />
            <b-button
              @click="newEmail(origin)"
              class="float-right"
            >+ New email</b-button>
            <b-button
              variant="warning"
              class="text-white"
              @click="removeOrigin(i)"
            >Delete origin</b-button>
          </b-card-text>
        </b-card>
        <br />
      </b-col>
    </b-row>
    <br />
    <b-card class="text-center action-panel">
      <b-row>
        <b-col md="6">
          <b-form-group class="float-right">
            <b-form inline>
              <b-form-input v-model="source" />
              <b-button @click="newOrigin">+ new origin</b-button>
            </b-form>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-button
            class="float-left"
            variant="primary"
            @click="save"
          >Save</b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  import RouteContext from "../../mixins/routeContext";
  import { mapState } from "vuex";
  import MessageIcon from "vue-material-design-icons/MessageOutline";
  export default {
    mixins: [RouteContext],
    components: { MessageIcon },
    data () {
      return {
        source: ""
      };
    },
    created () {
      this.$store.dispatch("messages/loadConf");
    },
    computed: {
      routeInfo () {
        return {
          title: this.$t("messages")
        };
      },
      ...mapState("messages", ["configuration"])
    },
    methods: {
      newEmail (origins) {
        origins.targets.push({ email: "" });
        this.$forceUpdate();
      },
      newOrigin () {
        this.$store.dispatch("messages/addOrigin", {
          targets: [{ email: "utopman@gmail.com" }],
          source: this.source
        });
        this.source = "";
      },
      save () {
        this.$store
          .dispatch("messages/save", this.configuration)
          .then(response => {
            if (response.status === 200) {
              this.$bvToast.toast("Configuration saved", {
                variant: "success"
              });
            } else {
              this.$bvToast.toast("Failed to save", {
                variant: "danger"
              });
            }
          });
      },
      removeTarget (target, targets) {
        const index = targets.indexOf(target);
        targets.splice(index, 1);
      },
      removeOrigin (i) {
        if (
          confirm(
            `Is Ok delete source ${this.configuration.origins[i].source} ?`
          )
        ) {
          this.$store.dispatch("messages/removeOrigin", i);
        }
      }
    }
  };
</script>
<style scoped>
.action-panel {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
}
</style>